import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'yxu4c39o',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2021-03-25',
  // token: 'sk5uPpAH8PCyXTbN4WM6IFgIADwDl9ls4TVWszlLYy3ZcvXKcbLkNA8p0CyjlWDI244x23AOhck3ajFIQIl0yi1EX7JPz7f7N7VgbnYvR22DmteBaTF7BjWd8IbeCoz1yAhaPjxfiCkGwxbvz2lGkpQpM75wYhAatlJD3rD2HJliqE8XMxb4',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
